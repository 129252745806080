/* body {font-family: Arial, Helvetica, sans-serif;} */

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 65px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.media_error_modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  padding-top: 65px; /* Location of the box */
  left: 0;
  top: 90;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  /* background-color: rgba(0,0,0,0.4);  */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 500px;
  max-width: 80%;
  position: relative;
  max-height: 100%;
  color: #111;
  /* padding-top: 455px; */
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* .post_btn {
    position: relative;
    padding: 6px;
    border-radius: 8px;
    font-size: 20px;
    background-color: #3498db;
    border-color: #3498db;
}

.cancel_btn {
  position: relative;
  padding: 6px;
  border-radius: 8px;
  font-size: 20px;
  background-color: #d92550;
  border-color: #d92550;
  margin-left: 10px;
} */


.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.post_btn {
  color: #fff !important;
  background-color: #92cf5c;
  border-color: #92cf5c;
}
.post_btn:hover {
  color: #fff !important;
  background-color: #7dc63c;
  border-color: #73b836;
}

.cancel_btn {
  border-color: #dae0ed;
  border-color: rgba(150,160,180,0.3);
  color: #717171 !important;
  background-color: #fff;
  box-shadow: inset 0 -1px 1px rgba(0,0,0,0.05);
  background-clip: padding-box;
}
.cancel_btn:hover {
  background-color: #fcfcfe;
}

#canvas_div{
  overflow-y: auto;
  position: absolute;
  top:5%;
  left: 3%;
  max-height: 80%;
  overflow: auto;
  width: 94%;
}

.MuiDialog-paperWidthSm {
  max-width: 500px;
  width: 500px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.start_call_btn.MuiButton-textPrimary {
  background-color: #92cf5c;
  color: white;
  padding: 10px;
}

.MuiInput-underline:before {
  border-bottom: 1px solid black;
}

.MuiInput-underline:after {
  color: white;
}

label + .MuiInput-formControl {
  margin-top: 20px;
}

label#name-label {
  color: white;
}

label#username-label {
  color: white;
}

div#form-dialog-title {
  margin-bottom: -20px;
}

.MuiPaper-root {
  color: #111;
}

.MuiFormLabel-root {
  color: #111;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  background-color: #424242;
}

.div_above_canvas {
  height: 350px;
  overflow: hidden;
  position: relative;
}

.div_above_canvas #canvas_div {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  top: 0;
  bottom: 0;
}

.div_above_canvas #canvas_div #canvas {
  object-fit: contain;
  height: 100%;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.pre-call-test-button {
  background-color: dimgray;
  padding: 10px;
}

.MuiDialogActions-root.MuiDialogActions-spacing {
  position: relative;
  text-align-last: center;
  justify-content: center;
}

svg.mic_svg_cls {
  /* position: absolute; */
  bottom: 18px;
  left: 30px;
}

.mic_level_txt {
  color: white;
  position: relative;
  left: 5px;
  width: 100%;
  font-size: 18px;
  text-align: left;
}